exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-delightful-taste-of-vietnamese-cuisine-js": () => import("./../../../src/pages/blog/delightful-taste-of-vietnamese-cuisine.js" /* webpackChunkName: "component---src-pages-blog-delightful-taste-of-vietnamese-cuisine-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-top-5-vietnamese-must-try-dishes-in-helsinki-js": () => import("./../../../src/pages/blog/top-5-vietnamese-must-try--dishes-in-helsinki.js" /* webpackChunkName: "component---src-pages-blog-top-5-vietnamese-must-try-dishes-in-helsinki-js" */),
  "component---src-pages-blog-vietnamese-food-in-helsinki-summer-experience-js": () => import("./../../../src/pages/blog/vietnamese-food-in-helsinki-summer-experience.js" /* webpackChunkName: "component---src-pages-blog-vietnamese-food-in-helsinki-summer-experience-js" */),
  "component---src-pages-catering-index-js": () => import("./../../../src/pages/catering/index.js" /* webpackChunkName: "component---src-pages-catering-index-js" */),
  "component---src-pages-catering-thank-you-js": () => import("./../../../src/pages/catering/thank-you.js" /* webpackChunkName: "component---src-pages-catering-thank-you-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-reserve-js": () => import("./../../../src/pages/reserve.js" /* webpackChunkName: "component---src-pages-reserve-js" */)
}

